const memberEngagementTagIds: Array<number> = (window.env.MEMBER_ENGAGEMENT_TAG_IDS || '')
  .split(',')
  .map(tagId => parseInt(tagId, 10));

const japanCountryCodes = ['JPN', 'JP'];
const docusignBlockList = [
  'AUS',
  'CHN',
  'HKG',
  'IDN',
  'JPN',
  'KOR',
  'MYS',
  'PHL',
  'POL',
  'RUS',
  'SGP',
  'THA',
  'TWN',
  'VNM',
];

export default Object.freeze({
  japanCountryCodes,
  docusignBlockList,
  apiGatewayBaseURI: {
    uri: window.env.API_GATEWAY_BASE_URI,
  },
  spaceman: {
    uri: window.env.SPACEMAN_URI,
  },
  operatorService: {
    uri: window.env.OPERATOR_SERVICE_URI,
  },
  inventoryManagementService: {
    uri: window.env.INVENTORY_MANAGEMENT_URI,
  },
  inventoryQuery: {
    uri: window.env.INVENTORY_QUERY_URI,
  },
  inventoryQueryEs: {
    uri: window.env.INVENTORY_QUERY_ES_URI,
  },
  id: {
    uri: window.env.ID_WEB_URI,
    webUri: window.env.ID_WEB_URI,
  },
  mena: {
    uri: window.env.MENA_API_URI,
    webUri: window.env.MENA_WEB_URI,
  },
  marketable_locations: {
    uri: window.env.GRPC_WEB_PROXY_URI,
  },
  floorplan_image_service: {
    uri: window.env.FLOORPLAN_IMAGE_SERVICE_URI,
  },
  contentful: {
    spaceCorePlatform: window.env.CONTENTFUL_SPACE_CORE_PLATFORM,
    environment: window.env.CONTENTFUL_ENVIRONMENT,
    cdn_token: window.env.CONTENTFUL_CDN_TOKEN,
    cdn_uri: window.env.CONTENTFUL_CDN_URL,
    preview_token: window.env.CONTENTFUL_PREVIEW_TOKEN,
    preview_uri: window.env.CONTENTFUL_PREVIEW_URL,
  },
  announcements: {
    uri: window.env.ANNOUNCEMENTS_URI,
  },
  communityEventsApi: {
    uri: window.env.COMMUNITY_EVENTS_API_URI,
  },
  recommendations: {
    uri: window.env.RECOMMENDATIONS_API_URI,
  },
  rooms: {
    uri: window.env.ROOMS_URI,
  },
  pegasus: {
    uri: window.env.PEGASUS_URI,
  },
  permissions: {
    uri: window.env.PERMISSIONS_API_URI,
  },
  spacemoney: {
    uri: window.env.SPACEMONEY_URI,
  },
  spatial_resource: {
    uri: window.env.GRPC_WEB_PROXY_URI,
  },
  salesAPI: {
    uri: window.env.SALES_API_URI,
    app: window.env.SALES_API_APP,
  },
  pricingHistory: {
    uri: `${window.env.API_GATEWAY_BASE_URI}/pricing-history`,
  },
  locationsAPI: {
    uri: window.env.LOCATIONS_API_URI,
    proxyUri: `${window.env.API_GATEWAY_BASE_URI}/locations`,
  },
  siGateway: {
    uri: window.env.SI_GATEWAY_URL,
  },
  memberNotifications: {
    uri: window.env.QUICK_MESSAGES_URI,
  },
  welkioAuthProxy: {
    uri: window.env.WELKIO_AUTH_PROXY_URI,
  },
  welkioUploads: {
    uri: window.env.WELKIO_UPLOADS_URI,
  },
  welkioAccess: {
    uri: window.env.WELKIO_URI,
    xEnvAuthentication: window.env.WELKIO_X_ENV_AUTHENTICATION,
  },
  algolia: {
    appId: window.env.ALGOLIA_APP_ID,
    securedKeyUri: window.env.ALGOLIA_SECURED_KEYS_URI,
    indices: {
      company: window.env.ALGOLIA_COMPANY_V2_INDEX,
      user: window.env.ALGOLIA_USER_V2_INDEX,
      contactInfo: window.env.ALGOLIA_CONTACT_INFO_INDEX,
      building: window.env.ALGOLIA_BUILDING_INDEX,
    },
  },
  skywriter: {
    uri: window.env.SKYWRITER_URI,
  },
  tours: {
    uri: window.env.TOURS_URI,
  },
  salesforce: {
    uri: window.env.SALESFORCE_URI,
    tickets: {
      uri: window.env.SALESFORCE_TICKETS_URI,
    },
    q2c: {
      uri: window.env.SALESFORCE_Q2C_URI,
    },
    orgC: {
      uri: window.env.SALESFORCE_ORGC_URI,
    },
    middle: window.env.SALESFORCE_MIDDLE,
    bookatour: window.env.SALESFORCE_BOOK_A_TOUR,
  },
  google: {
    id: window.env.GOOGLE_OAUTH_ID,
    mapsApiKey: window.env.GOOGLE_MAPS_API_KEY,
  },
  airlock: {
    uri: window.env.AIRLOCK_URI,
  },
  emailTemplatesApi: {
    uri: window.env.EMAIL_TEMPLATES_API_URI,
  },
  netverify: {
    isEnabledForHotDeskAndWeMembers: window.env.NETVERIFY_ENABLED_WE_MEMBERS,
  },
  toursAPI: {
    tours: {
      uri: window.env.SALESFORCE_VISITOR_TOURS_URI,
    },
    accountDetail: {
      uri: window.env.SALESFORCE_ACCOUNT_DETAIL_URI,
    },
  },
  mulesoftAPI: {
    uri: window.env.MULESOFT_URL,
    client_id: window.env.MULESOFT_CLIENT_ID,
    token: window.env.MULESOFT_TOKEN,
  },
  split: {
    token: window.env.SPLIT_TOKEN,
    experiments: [
      'ssAccessLevelGroups',
      'ssAccessReports',
      'ssAccountConfiguration',
      'ssAgreementPdfUploadForAddOnsAndDiscounts',
      'ssAllAccessIvanhoeCambridge',
      'ssAllAccessLite',
      'ssAllAccessMultiProduct',
      'ssAllAccessSaksworks',
      'ssAmendmentAutomation',
      'ssBulkContractorUpdate',
      'ssBusinessAddress',
      'ssEarlyTerminationPenalty',
      'ssGlobalAccessTransfer',
      'ssInventoryAvailabilityFilter',
      'ssInventoryBuildingFilter',
      'ssInventoryComparison',
      'ssInventoryNewAttributes',
      'ssInventoryNewWorkUnits',
      'ssInventoryOfficeLoader',
      'ssInventoryRoomGrouping',
      'ssInventoryRSF',
      'ssInventorySearchMultipleReservablesSelect',
      'ssIQSPoweredAddOfficeModal',
      'ssManualAgreementInNewAmendmentsFlow',
      'ssMembershipDownsize',
      'ssMicrositePOC',
      'ssMimoChecklistRequestsTaskAutomation',
      'ssMultiLocationAllAccess',
      'ssNewDiscountExperience',
      'ssNewEmployeeTab',
      'ssNewRenewalFlow',
      'ssOccupancyData',
      'ssOnDemandDeskBooking',
      'ssOperatorService',
      'ssOrganizationPage',
      'ssOrgStructure',
      'ssOutOfPolicyGovernanceFlow',
      'ssPaperworkDecommissionforQ2C',
      'ssPromotions',
      'ssPublishMaCreationEvents',
      'ssRenewalPlusUpgrade',
      'ssRenewals',
      'ssSelfExecutingAmendments',
      'ssSendDraftMA',
      'ssSfOpportunity',
      'ssSfOpportunityAddOns',
      'ssUseLocationsOrigin',
      'ssVitalStats',
      'ssWeeklyPoster',
      'ssEnableKubeMigratedProperties',
      'ssFilterKubeLocations',
    ],
  },
  bans: {
    uri: window.env.BANS_URI,
  },
  uri: window.location.origin,
  wework_company_uuid: window.env.WEWORK_COMPANY_UUID,
  setupFeeResourceUuid: window.env.SETUP_FEE_RESOURCE_UUID,
  allAccessPlusResourceUuid: window.env.ALL_ACCESS_PLUS_RESOURCE_UUID,
  workplaceSubscriptionResourceUuid: window.env.WORKPLACE_SUBSCRIPTION_RESOURCE_UUID,
  globalAccessTrialResourceUuid: window.env.GLOBAL_ACCESS_TRIAL_RESOURCE_UUID,
  globalAccessSaksWorksResourceUuid: window.env.GLOBAL_ACCESS_SAKS_WORKS_RESOURCE_UUID,
  allAccessBasicResourceUuid: window.env.ALL_ACCESS_BASIC_RESOURCE_UUID,
  onDemandBPOResourceUuid: window.env.ON_DEMAND_BPO_RESOURCE_UUID,
  pxWeResourceUuid: window.env.PXWE_RESOURCE_UUID,
  keycardReplacementResourceUuid: window.env.KEYCARD_REPLACEMENT_RESOURCE_UUID,
  extraMemberResource: window.env.EXTRA_MEMBER_RESOURCE_UUID,
  accessLevelsPerPage: window.env.ACCESS_LEVELS_PER_PAGE || 5,
  version: window.env.APP_VERSION,
  appEnv: window.env.APP_ENV,
  appName: window.env.APP_NAME,
  nodeEnv: window.env.NODE_ENV,
  branch: window.env.BRANCH,
  manifestUrl: window.env.MANIFEST_URL,
  rollbarToken: window.env.ROLLBAR_TOKEN,
  hdEnabledCountries: window.env.HD_ENABLED_COUNTRIES,
  hardcopyProcessBuildingCountries: window.env.HARDCOPY_PROCESS_BUILDING_COUNTRIES,
  manualUploadCountries: window.env.MANUAL_UPLOAD_COUNTRIES,
  RollOutCountries: window.env.ROLL_OUT_COUNTRIES || '',
  segmentKeyInventoryTool: window.env.SEGMENT_KEY_INVENTORY_TOOL,
  zendesk: {
    uri: window.env.ZENDESK_URI,
  },
  memberEngagementTagIds,
  milkyway: {
    id: window.env.MILKYWAY_CLIENT_ID,
    api: window.env.MILKYWAY_API,
    uri: window.env.MILKYWAY_URI,
    tokenUri: window.env.MILKYWAY_TOKEN_URI,
  },
  movesApi: {
    uri: window.env.MOVES_API,
  },
  movesPortalApi: {
    uri: window.env.MOVES_PORTAL_API,
  },
  addonsPricingFaqUrl: window.env.ADDONS_PRICING_FAQ_URL || '',
  discounts: {
    uri: window.env.DISCOUNTS_URI,
  },
  productService: {
    uri: window.env.PRODUCT_SERVICE_URI,
  },
  weworkAnywhereLocationUuid: window.env.WEWORK_ANYWHERE_LOCATION_UUID || '',
  welkioPusherKey: window.env.WELKIO_PUSHER_KEY,
  weMembershipUuid: window.env.WE_MEMBERSHIP_UUID || '',
  conferenceRoomUuid: window.env.CONFERENCE_ROOM_PRODUCT_UUID || '',
  workspaceUuid: window.env.DAILY_DESK_PRODUCT_UUID || '',
  extraMemberProductUuid: window.env.EXTRA_MEMBER_PRODUCT_UUID || '',
  bpGateway: {
    uri: window.env.BP_GATEWAY_URL,
  },
  upcomingBill: {
    uri: window.env.UPCOMING_BILL_URL,
  },
  networkAnalysis: {
    auth: window.env.SS_NETWORK_ANALYSIS_AUTH,
    uri: window.env.SS_NETWORK_ANALYSIS_URL,
  },
  communityMetrics: {
    uri: window.env.SS_COMMUNITY_METRICS,
  },
  quoteOrderFulfill: {
    uri: window.env.QUOTE_ORDER_FULFILL_URL,
  },
  tasksApi: {
    uri: window.env.TASKS_API_URI,
  },
  stripeWrapperService: {
    uri: window.env.STRIPE_WRAPPER_SERVICE_URL,
  },
  accountService: {
    uri: window.env.ACCOUNT_SERVICE_URI,
  },
  accessReportingService: {
    uri: window.env.ACCESS_REPORTING_SERVICE_URI,
  },
  auth0: {
    domain: window.env.AUTH0_DOMAIN,
    clientId: window.env.AUTH0_CLIENTID,
    redirectorUri: window.env.AUTH_REDIRECTOR_URI,
  },
  buildingDocumentsApi: {
    uri: window.env.BUILDING_DOCUMENTS_API,
  },
  notifyService: {
    pusherKey: window.env.NOTIFY_PUSHER_KEY,
    uri: window.env.NOTIFY_URI,
  },
  missionControl: {
    uri: window.env.MISSION_CONTROL_URI,
  },
  mxGraphql: {
    uri: window.env.MX_GQL_URI,
  },
  kube: {
    uri: window.env.KUBE_BASE_URL,
  },
});
